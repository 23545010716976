import React from "react"
import ServiceDash from "../../components/service-dash"
import shuffle from "../../functions/shuffle"

const CaseFile = () => {
  let template = new ServiceDash()

  let caseFiles = [
    "Conoco Inc",
    "Deluxe Corporation",
    "Comerica Inc",
    "Delphi Corp",
    "Burlington Northern",
    "CMS Energy Corp",
    "Costco Wholesale Corp",
    "D&K Healthcare",
    "GMGI Inc",
    "Sundance Design Systems",
    "The Pokémon Company",
  ]

  shuffle(caseFiles)

  template.serviceType = "CaseFile"
  template.serviceArray = caseFiles

  template.uiAnimateLines()

  return template
}

export default CaseFile